import React from "react";
import styled from "styled-components";


/* Dots Decoration Group */

interface DotsGroup {
    quantity:number,
    left:string,
    top:string,
    type:string,
}

const DotsGroup: React.FunctionComponent<DotsGroup> = ({
    quantity, left, top, type
}): React.ReactElement => {


  let arrayDotsEl = Array.from(Array(quantity), (_, i) => i + 1)
  return (
    <StyledDotsGroup left={left} top={top} type={type}>
        
        {arrayDotsEl.map((dotEl,key) => {
            return (
              <DotElement key={key} type={type}/>
            )
        })}
    </StyledDotsGroup>
  );
};

const StyledDotsGroup = styled.div`
    max-width:${({ type }) => {
      if (type === 'small') return '64px;';
      if (type === 'big') return '202px;';
    }};
    position:absolute;
    flex-wrap:wrap;
    height:auto;
    min-height:${({ type }) => {
      if (type === 'small') return '52px;';
      if (type === 'big') return '102px;';
    }};
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: center;
    left:${props => props.left};
    top:${props => props.top};  
`

const DotElement = styled.div`
    width: ${({ type }) => {
      if (type === 'small') return '3.7px;';
      if (type === 'big') return '6px;';
    }};
    height: ${({ type }) => {
      if (type === 'small') return '3.7px;';
      if (type === 'big') return '6px;';
    }};
    background-color: #DAAFC3;
    opacity:0.3;
    border-radius: 50%;
    display: inline-block;
    margin: ${({ type }) => {
      if (type === 'small') return '3.7px;';
      if (type === 'big') return '6px;';
    }};
    @media (max-width:768px) {
      width:5.22px;
      width:5.22px;
      width:5.22px;
    }
    @media (max-width:375px) {
      width:3.7px;
      width:3.7px;
      width:3.7px;
    }
`
    
export default DotsGroup;