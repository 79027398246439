import React, { useEffect, useState } from "react"
import styled, { ThemeProvider } from "styled-components"
import { GatsbyLocation } from "local-types"
import { Link } from "gatsby"
import Header, { HeaderProps } from "./Header"

import "../../styles/global.css"

import { device, colors } from "../../styles/constants"

interface LayoutProps {
  location?: GatsbyLocation
  title?: string
  headerProps?: HeaderProps
  previousPath?: string
  nextPath?: string
}

const Layout: React.FunctionComponent<LayoutProps> = ({
  children,
  previousPath,
  nextPath,
  headerProps,
  location,
}): React.ReactElement => {
  const [isHovered, setHovered] = useState(false)
  const [hoverIcon, setHoverIcon] = useState(false)
  const activeRoute = /\b(\w*introduction\w*)\b/g
  const found = location.pathname.match(activeRoute)

  return (
    <ThemeProvider theme={{ colors, device }}>
      <Root>
        <Header location={location} {...headerProps} />
        <Main>{children}</Main>
      </Root>
    </ThemeProvider>
  )
}

/*
   <ChatIcon
    onMouseEnter={() => setHoverIcon(true)}
    onMouseLeave={() => setHoverIcon(false)}
  >
    <img src={  (hoverIcon ? "/hover-icon.png" : "/chat-icon.png") } alt="chat-icon" />
  </ChatIcon>

*/

const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: 100vh;
  background: #ffffff;
  img {
    margin-bottom: 0;
    display: block !important;
  }
  overflow: hidden;
`

const Main = styled.main`
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
`

const ChatIcon = styled.div`
  position: fixed;
  top: 85%;
  right: 165px;
  z-index: 1000;
  img {
    margin-bottom: 0;
    @media ${device.mobileL} {
      width: 38px;
      height: 38px;
    }
  }
  @media ${device.laptopL} {
    bottom: 32px;
    right: 64px;
  }
  @media ${device.tablet} {
    position: fixed;
    right: 45px;
    top: 92%;
  }
  @media ${device.mobileL} {
    top: 80%;
    right: 10px;
  }
  @media ${device.mobileM} {
    top: 83%;
  }
  @media ${device.mobileS} {
    top: 75%;
  }
`

export default Layout
