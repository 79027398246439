import React from "react";
import styled from "styled-components";

/* Elements Decoration Icons */

interface ElementIcons {
    left:string,
    top:string,
    type: string,
    opacity: string,
    positionX: string,
    positionY: string,
}

const ElementIcons: React.FunctionComponent<ElementIcons> = ({
    left, top,  type, opacity
}): React.ReactElement => {
  const positionX = Math.floor(Math.random() * 100).toString();
  const positionY = Math.floor(Math.random() * 100).toString();
  return type === 'icon-circle' ? (
            <IconWrapper left={left} top={top}  opacity={opacity} positionX={positionX} positionY={positionY}>
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.42" d="M6.12584 29.8892C-1.65116 52.1412 9.77584 76.1922 34.8538 79.0422C58.2628 81.7022 80.8558 60.9192 79.0818 37.1552C77.4378 15.1312 55.7608 -2.81176 33.8878 0.366244C12.7338 3.44024 -7.27816 28.4202 2.59384 49.5172C5.70284 56.1612 16.7198 53.5892 17.7958 46.8142C19.6838 34.9282 20.2638 23.1742 33.4678 18.1082C45.8018 13.3762 59.5558 23.3802 61.9428 35.8302C64.4788 49.0573 52.2558 61.9602 39.2638 62.3702C24.9078 62.8232 10.2228 45.0602 11.5188 31.0452C11.8468 27.4812 7.30384 26.5172 6.12584 29.8892Z" fill="#A62651"/>
                </svg>
            </IconWrapper>
        ) : type === 'icon-figure' ? (
            <IconWrapper left={left} top={top} opacity={opacity} positionX={positionX} positionY={positionY}>
                <svg width="63" height="60" viewBox="0 0 63 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.37" d="M5.51431 21.0519C13.8683 32.7688 22.8059 49.2725 35.151 57.2611C50.1905 66.9929 54.0931 47.7443 56.907 37.1577C59.5497 27.2165 69.7687 5.36906 54.4003 0.609609C47.5522 -1.51146 39.365 2.43352 32.9551 4.34018C23.6765 7.10016 14.3986 9.86013 5.12003 12.6194C-4.23986 15.4031 0.398718 30.1729 9.6773 27.1064C16.5708 24.8285 23.4635 22.5498 30.357 20.2719C34.2156 18.997 47.8946 12.1719 51.6943 14.8448C50.5668 13.4972 49.4387 12.1503 48.3112 10.8034C50.5913 15.9442 45.6016 27.4395 44.3641 32.6033C43.5273 36.095 42.3459 39.6738 41.8178 43.2324C40.9637 48.9891 41.2444 46.0895 37.7138 42.8914C28.2165 34.2891 19.4034 25.6717 8.91392 18.2286C7.05115 16.9068 3.9012 18.7883 5.51431 21.0519Z" fill="#A62651"/>
                </svg>          
            </IconWrapper>
        ) : type === 'icon-film' ? (
            <IconWrapper left={left} top={top}  opacity={opacity} positionX={positionX} positionY={positionY}>
                <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.3">
                        <path d="M38.0914 11.8818H4.09722C1.83521 11.8845 0.00266746 13.7171 0 15.9791V42.9418C0.00266746 45.2038 1.83521 47.0363 4.09722 47.039H38.0914C40.3528 47.0363 42.1859 45.2038 42.1886 42.9418V15.9791C42.1859 13.7171 40.3528 11.8845 38.0914 11.8818Z" fill="#A62651"/>
                        <path d="M44.92 35.0722L59 42.7593V16.2495L44.92 23.9366V35.0722Z" fill="#A62651"/>
                    </g>
                </svg>
            </IconWrapper>
        ) : type === 'icon-pencil' ? (
            <IconWrapper left={left} top={top}  opacity={opacity} positionX={positionX} positionY={positionY}>
                <svg width="71" height="78" viewBox="0 0 71 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.41" d="M9.09605 76.9732C21.069 71.4334 29.2733 59.3575 37.8836 49.6862C47.9836 38.3431 58.1016 27.0156 68.1417 15.6187C76.5281 6.09829 61.0547 -6.04352 52.7043 3.43617C43.1983 14.2284 33.7641 25.0852 24.3155 35.9289C15.8561 45.6373 4.33106 55.5194 0.343262 67.9953C-1.31533 73.1818 3.26884 79.6689 9.09605 76.9732Z" fill="#A62651"/>
                </svg>
            </IconWrapper>
        ) : type === 'icon-points' ? (
            <IconWrapper left={left} top={top}  opacity={opacity} positionX={positionX} positionY={positionY}>
                <svg width="122" height="148" viewBox="0 0 122 148" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.15">
                        <path d="M0 13L122 0V122L0 135V13Z" fill="#DAAFC3"/>
                        <path d="M0 147.5V126.5H16L0 147.5Z" fill="#DAAFC3"/>
                    </g>
                    <g opacity="0.3" clip-path="url(#clip0)">
                        <path d="M90.9348 59.7958C94.2789 62.775 94.5747 67.9011 91.5955 71.2452C88.6163 74.5893 83.4903 74.8852 80.1461 71.906C76.802 68.9268 76.5062 63.8007 79.4854 60.4566C82.4646 57.1124 87.5907 56.8166 90.9348 59.7958Z" fill="#A62651"/>
                        <path d="M66.6472 61.1977C69.9913 64.1769 70.2871 69.3029 67.3079 72.6471C64.3287 75.9912 59.2027 76.287 55.8585 73.3078C52.5144 70.3286 52.2186 65.2025 55.1978 61.8584C58.177 58.5143 63.3031 58.2185 66.6472 61.1977Z" fill="#A62651"/>
                        <path d="M42.3593 62.599C45.7035 65.5782 45.9993 70.7043 43.0201 74.0484C40.0409 77.3925 34.9148 77.6884 31.5707 74.7092C28.2266 71.73 27.9308 66.6039 30.9099 63.2598C33.8891 59.9157 39.0152 59.6198 42.3593 62.599Z" fill="#A62651"/>
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="64.8754" height="64.8754" fill="white" transform="translate(27 36.7378) rotate(-3.30293)"/>
                        </clipPath>
                    </defs>
                </svg>
            </IconWrapper>
        ) : type === 'icon-rectangle' ? (
            <IconWrapper left={left} top={top}  opacity={opacity} positionX={positionX} positionY={positionY}>
                <svg width="122" height="130" viewBox="0 0 122 130" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.15" d="M0 0H122V112L0 130V0Z" fill="#DAAFC3"/>
                </svg>
            </IconWrapper>
        ) : type === 'icon-wave' ? (
            <IconWrapper left={left} top={top}  opacity={opacity} positionX={positionX} positionY={positionY}>
                <svg width="92" height="90" viewBox="0 0 92 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.47" d="M84.8213 73.6881C72.8673 76.4731 60.4773 76.3331 55.9553 64.2301C52.5883 55.2181 58.4063 48.6601 60.8483 40.1011C63.6153 30.4031 61.7793 20.0811 54.7053 11.9381C41.2593 -3.54094 8.77732 -5.32595 0.410319 14.6821C-1.67968 19.6791 4.60932 25.9281 10.3433 23.1401C17.2623 19.7761 24.8163 13.989 33.4413 16.707C46.4023 20.791 44.3923 33.753 41.1883 42.495C37.5593 52.396 34.7773 61.51 39.8153 71.711C48.3403 88.971 74.1833 96.7641 89.4183 82.6481C92.7843 79.5311 90.9093 72.2701 84.8213 73.6881Z" fill="#A62651"/>
                </svg>
            </IconWrapper>
        ) :  type === 'icon-two-rec' ? (
            <IconWrapper left={left} top={top}  opacity={opacity} positionX={positionX} positionY={positionY}>
                <svg width="152" height="72" viewBox="0 0 152 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.3" x="0.594482" y="30.251" width="145.135" height="42.5939" transform="rotate(-11.7014 0.594482 30.251)" fill="#DAAFC3"/>
                </svg>
            </IconWrapper>
        ) : (
            <></>
        )
};

const IconWrapper = styled.div`
    position:absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width:${props => props.size};
    left:${props => props.left};
    top:${props => props.top};
    opacity:${props => props.opacity};
    
    @media (max-width:800px) {
        width:64px;
        top:${props => props.positionX}%;
        left:${props => props.positionY}%;
    }
    @media (max-width:450px) {
        width:32px;
    }
`
  
export default ElementIcons;