import { GatsbyLocation } from "local-types"
import React from "react"
import styled from "styled-components"
import NavbarLink from "../../NavbarLink"
import Logo from "../Logo"
import ElementIcons from "../../DecorationElements/ElementsIcons"
import DotsGroup from "../../DecorationElements/DotsGroup"

import { colors, device } from "../../../styles/constants"

const LinksList = [
  {
    route: "/introduction",
    title: "Introduction",
    className: "active",
  },
  {
    route: "/AboutUs",
    title: "About us",
  },
  {
    route: "/Careers",
    title: "Careers",
  },
  {
    route: "/blog",
    title: "Blog",
    className: "activeBlog",
  },
  {
    route: "/Contact",
    title: "Contact",
  },
]

export interface NavLinks {
  location?: GatsbyLocation
}

const NavLinks: React.FunctionComponent<NavLinks> = ({
  location,
}): React.ReactElement => {
  const activeRoute = /\b(\w*introduction\w*)\b/g
  const activeBlog = /\b(\w*blog\w*)\b/g
  const found = location.pathname.match(activeRoute)
  const foundBlog = location.pathname.match(activeBlog)
  let subActive
  if (found !== null && foundBlog === null) {
    subActive = "active"
  }
  if (foundBlog !== null && found === null) {
    subActive = "activeBlog"
  }

  return (
    <>
      <IconsMobile>
        <ElementIcons
          type="icon-points"
          left="53%"
          top="18%"
          size="91px"
          opacity="0.8"
        />
        <ElementIcons
          type="icon-wave"
          left="30%"
          top="72%"
          size="91px"
          opacity="0.41"
        />
        <ElementIcons
          type="icon-pencil"
          left="41%"
          top="29%"
          size="58px"
          opacity="0.41"
        />
        <ElementIcons
          type="icon-figure"
          left="65%"
          top="24%"
          size="122px"
          opacity="0.42"
        />
        <DotsGroup quantity={66} left="63%" top="14%" type="big" />
      </IconsMobile>
      <StyledNav>
        <MobileLogoWrapper>
          <Logo />
        </MobileLogoWrapper>
        {LinksList.map(link => {
          return (
            <NavbarLink
              toRoute={link.route}
              state={{ prevPath: location.pathname }}
              className={
                (found !== null &&
                foundBlog === null &&
                link.className === "active"
                  ? link.className
                  : "") +
                (foundBlog !== null &&
                found === null &&
                link.className === "activeBlog"
                  ? link.className
                  : "")
              }
            >
              {link.title}
            </NavbarLink>
          )
        })}
      </StyledNav>
    </>
  )
}

export default NavLinks

const StyledNav = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  transition: 0.2s;
  position: relative;
  z-index: 1010;
  box-sizing: border-box;

  @media ${device.laptop} {
    width: 600px;
    margin: 0 auto;
    justify-content: space-between;
  }
  @media ${device.mobileL} {
    top: 0;
    flex-direction: column;
    width: 200px;
    align-items: flex-start;
    margin-left: 0;
  }

  .active {
    color: ${colors.mainColor};
    border-bottom: 3px solid ${colors.mainColor};
    @media (max-width: 1024px) {
      border-bottom: 0;
      border-top: 3px solid ${colors.mainColor};
    }
    @media ${device.mobileL} {
      border: none;
    }
  }
  .activeBlog {
    color: ${colors.mainColor};
    border-bottom: 3px solid ${colors.mainColor};
    @media (max-width: 1024px) {
      border-bottom: 0;
      border-top: 3px solid ${colors.mainColor};
    }
    @media ${device.mobileL} {
      border: none;
    }
  }
`

const MobileLogoWrapper = styled.div`
  position: relative;
  left: 0;
  top: 0;
  @media (min-width: 425px) {
    display: none;
  }
`

const IconsMobile = styled.div`
  @media (min-width: 425px) {
    display: none;
  }
`
