// Logo

import React from "react"
import Image from 'gatsby-image'
import { useStaticQuery, graphql } from "gatsby"


const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
        file(absolutePath: {regex: "/logo_full_rgb.png/"}) {
        childImageSharp {
            fixed(width: 180) {
                ...GatsbyImageSharpFixed
            }
        }
      }
    }
  `)
  return (
    <Image fixed={data.file.childImageSharp.fixed} alt="logo" />  
  )
}

export default Logo




