import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { GatsbyLocation } from "local-types"

import { rhythm, scale } from "../../../utils/typography"
import { device, colors } from "../../../styles/constants"
import NavLinks from "../NavLinks"
import { Dropdown, Menu, Modal } from "antd"
import Logo from "../Logo"
import ModalHeader from "../ModalHeader"
// import PricingModal from "../PricingModal"
import TryOutModal from "../TryOutModal"

const langs = ["En", "Nl", "Sp"]

export interface HeaderProps {
  showBackNav: boolean
  location?: GatsbyLocation
}

const Header: React.FunctionComponent<HeaderProps> = ({
  showBackNav = false,
  location,
}): React.ReactElement => {
  const [navbarOpen, setNavbarOpen] = useState(false)
  const [visible, setVisible] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const onOptionClicked = value => () => {
    setSelectedOption(value)
  }

  const menu = (
    <StyledMenu>
      {langs.map(lang => (
        <DropdownLink onClick={onOptionClicked(lang)} key={Math.random()}>
          {lang}
        </DropdownLink>
      ))}
    </StyledMenu>
  )

  // <TryOutModal callToAction="Try it Out" />
  return (
    <Root>
      <StyledHeader>
        <Title>
          <Link
            aria-label={
              showBackNav && location.state && location.state.prevPath
                ? "Back"
                : "Home"
            }
            rel="back"
            to={
              showBackNav && location.state && location.state.prevPath
                ? location.state.prevPath
                : "/introduction"
            }
            state={{ prevPath: location.pathname }}
          >
            <Logo />
          </Link>
        </Title>
        {navbarOpen ? (
          <Navbox>
            <NavLinks location={location} />
          </Navbox>
        ) : (
          <Navbox open>
            <NavLinks location={location} />
          </Navbox>
        )}
        <ButtonsWrapper>
          <ModalHeader callToAction="Schedule Demo" />
        </ButtonsWrapper>
        <Toggle
          navbarOpen={navbarOpen}
          onClick={() => setNavbarOpen(!navbarOpen)}
        >
          {navbarOpen ? <Hamburger open /> : <Hamburger />}
        </Toggle>
      </StyledHeader>
    </Root>
  )
}

/* Dropdown languages

  <Dropdown overlay={menu}  placement="bottomCenter" trigger={['click']}>
    <DropdownElement> {selectedOption || "En"}</DropdownElement>
  </Dropdown>

*/

const StyledMenu = styled(Menu)`
  width: 80px;
  text-align: center;
  display: flex;
  flex-direction: column;
  @media ${device.laptopL} {
    width: 60px;
  }
`

const DropdownLink = styled.a`
  cursor: pointer;
  margin: 4px 0;
  text-transform: uppercase;
  font-family: FreightSans;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: ${colors.dark} !important;
  :hover {
    color: ${colors.mainColor} !important;
  }
`

const Root = styled.div`
  position: fixed;
  background-color: #ffffff;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 80px;
  box-shadow: 0 4px 60px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${rhythm(3 / 4)};
  transition: height 0.5s;

  @media ${device.laptop} {
    height: 60px;
  }
  @media ${device.tablet} {
    padding: 0;
  }
  @media ${device.mobileL} {
    height: 48px;
    bottom: 0;
    top: auto;
    padding: 0;
  }
  &.smaller {
    height: ${rhythm(2)};
  }
`

const StyledHeader = styled.header`
  position: relative;
  z-index: 1000;
  background-color: #ffffff;
  width: 100%;
  max-width: 1600px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media ${device.desktop} {
    max-width: 1300px;
  }
  @media ${device.laptopL} {
    max-width: 1200px;
    padding: 0 64px;
  }
  @media ${device.tablet} {
    padding: 0 32px;
  }
  @media ${device.mobileL} {
    padding: 0;
  }
`

const Title = styled.div`
  margin: 0;
  background-color: #ffffff;
  position: relative;
  z-index: 1001;
  div {
    @media ${device.laptop} {
      height: 60px !important;
    }
  }

  img {
    background-color: #ffffff;
  }
  a {
    color: inherit;
    text-decoration: none;
    box-shadow: none;
    background-color: #ffffff;
    display: block;
    height: 80px;
    @media ${device.laptop} {
      height: 60px;
    }
  }
  @media ${device.tablet} {
    margin-bottom: 0;
    ${scale(0.5)};
  }
  @media ${device.mobileL} {
    display: none;
  }
`

const ButtonsWrapper = styled.div`
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 300px;
  position: relative;
  z-index: 1000;
  background-color: #ffffff;
  div {
    max-height: 62px;
  }
  div {
    @media ${device.laptop} {
      margin: 0 8px;
    }
    @media ${device.mobileL} {
      margin: 0 6px 0 24px;
      height: 48px;
    }
    @media ${device.mobileS} {
      margin: 0 12px;
    }
  }
  @media (max-width: 1150px) {
    max-width: 220px;
  }

  @media ${device.laptop} {
    max-width: 800px;
    justify-content: flex-end;
    padding-right: 50px;
    height: 60px;
  }
  @media ${device.tablet} {
    padding-right: 12px;
    justify-content: center;
  }
  @media ${device.mobileM} {
    padding-right: 16px;
  }
  @media ${device.mobileL} {
    justify-content: flex-start;
    height: 62px;
  }
`

const Toggle = styled.div`
  display: none;
  height: 80px;
  cursor: pointer;
  padding: 0 16px;
  background-color: #ffffff;
  position: relative;
  z-index: 1001;
  @media ${device.laptop} {
    display: flex;
    height: 60px;
  }
  @media ${device.mobileL} {
    padding: 0;
  }
`

const Navbox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  height: auto;
  position: relative;
  z-index: 1000;

  @media ${device.laptop} {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    background-color: #fff;
    transition: all 0.3s ease-in;
    left: 0;
    top: ${props => (props.open ? "0" : "60px")};
    height: 60px;
  }
  @media ${device.mobileL} {
    height: 100%;
    width: 100%;
    background-color: ${colors.mainLight};
    top: ${props => (props.open ? "-100%" : "0")};
  }
`

const Hamburger = styled.div`
  background-color: ${colors.mainColor};
  width: 24px;
  height: 2px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 24px;
    height: 2px;
    background-color: ${colors.mainColor};
    content: "";
    position: absolute;
    transition: all 0.3s linear;
    @media ${device.mobileL} {
      width: 22px;
      height: 2px;
    }
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-6px, 0px)" : "rotate(0deg)"};
    top: -6px;
    @media ${device.mobileL} {
      top: -6px;
      transform: ${props =>
        props.open ? "rotate(-90deg) translate(-6px, 0px)" : "rotate(0deg)"};
    }
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 6px;
    @media ${device.mobileL} {
      top: 6px;
      transform: ${props =>
        props.open ? "rotate(-90deg) translate(6px, 0px)" : "rotate(0deg)"};
    }
  }
  @media ${device.mobileL} {
    width: 22px;
    height: 2px;
    margin-right: 16px;
    margin-bottom: 8px;
  }
`

const DropdownElement = styled.div`
  cursor: pointer;
  margin: 12px 0;
  text-transform: uppercase;
  font-family: FreightSans;
  font-weight: 600;
  font-size: 16px;
  color: ${colors.dark} !important;
  position: relative;
  z-index: 1001;
  background-color: #ffffff;
  @media ${device.mobileL} {
    padding: 12px 24px 12px 12px;
    margin: 0;
  }
`

export default Header
