import React from "react"
import styled from "styled-components"

import FlipbaseButton from "../../FlipbaseButton"
import { Modal } from "antd"
import { device } from "../../../styles/constants"
import { InlineWidget } from "react-calendly"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'


class ModalHeader extends React.Component {
  state = { visible: false }
  
  showModal = (e) => {
    this.setState({
      visible: true,
    })
    e.preventDefault()
        // Lets track that custom click
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: "Schedule Demo",
      // string - required - Type of interaction (e.g. 'play')
      action: "click",
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: "Schedule Demo",
      // number - optional - Numeric value associated with the event. (e.g. A product ID)
      value: 43
    })
  }

  handleOk = e => {
    this.setState({
      visible: false,
    })

  }

  handleCancel = e => {
    this.setState({
      visible: false,
    })
  }
  
  render() {
    
    return (
      <>
        <div onClick={this.showModal} className="centered">
          <FlipbaseButton type="primary" onClick={this.showModal} >
            {this.props.callToAction}
          </FlipbaseButton>
        </div>
        <StyledModal
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          centered
          footer={[]}
          width={960}
        >
          <InlineWidget url="https://calendly.com/stephan-flipbase/website-demo" />
        </StyledModal>
      </>
    )
  }
}

const StyledModal = styled(Modal)`
  .ant-modal-close-x {
    color: #a62651;
  }

  padding-bottom: 0px !important;
  @media ${device.laptopL} {
    max-width: calc(100vw - 32px) !important;
  }
  @media ${device.mobileL} {
    padding-bottom: 96px !important;
  }
  .ant-modal-body {
    width: 100%;
    height: auto;
    padding: 0 !important;
    @media (max-width: 575px) {
      padding: 40px 16px 16px;
    }
  }
  .ant-modal-footer {
    padding: 0;
  }
`

export default ModalHeader
