import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

/* Flipbase Button */

/*
 Button types:
   - primary
   - secondary
*/

interface FlipbaseButton {
  toRoute: string
  type: string
  fullWidth: boolean
  htmlType: string
  active?: boolean
  disabled: boolean
}

const FlipbaseButton: React.FunctionComponent<FlipbaseButton> = ({
  children,
  toRoute,
  type,
  active = true,
  disabled,
  fullWidth,
  htmlType,
  ...otherProps
}): React.ReactElement => {
  const internal = /^\/(?!\/)/.test(toRoute)

  if (internal) {
    return (
      <Link to={toRoute}>
        <FlipbaseButtonStyled type={type} disabled={disabled} {...otherProps}>
          {children}
        </FlipbaseButtonStyled>
      </Link>
    )
  }

  if (htmlType === "submit" || !active) {
    return (
      <FlipbaseButtonStyled type={type} disabled={disabled} {...otherProps}>
        {children}
      </FlipbaseButtonStyled>
    )
  }

  return (
    <a href={toRoute} target="_blank" style={{ width: "100%" }}>
      <FlipbaseButtonStyled type={type} disabled={disabled} {...otherProps}>
        {children}
      </FlipbaseButtonStyled>
    </a>
  )
}

const FlipbaseButtonStyled = styled.button`
  font-family: FreightSans;
  font-weight: 600;
  display: inline-block;
  border-radius: 3px;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 25px !important;
  text-align: center;
  position: relative;
  z-index: 10;
  width: 100%;
  padding: ${({ type }) => {
    if (type === "primary") return "12px 24px 12px;"
    if (type === "secondary") return "12px 24px 12px;"
    if (type === "tertiary") return "10px 16px;"
  }};
  max-width: ${({ type }) => {
    if (type === "primary") return "160px"
    if (type === "secondary") return "180px"
    if (type === "tertiary") return "180px"
  }};
  background-color: ${({ type }) => {
    if (type === "primary") return "#A62651"
    if (type === "secondary") return "rgba(166, 38, 81, 0.1)"
    if (type === "tertiary") return "#FFFFFF"
  }};
  box-shadow: ${({ type }) => {
    if (type === "primary") return "0px 4px 8px -2px rgba(166, 38, 81, 1)"
    if (type === "tertiary") return "0px 8px 15px rgba(0, 0, 0, 0.08)"
  }};
  color: ${({ type }) => {
    if (type === "primary") return "#FFFFFF"
    if (type === "secondary") return "#A62651"
    if (type === "tertiary") return "#A62651"
  }};
  margin-bottom: ${({ type }) => {
    if (type === "tertiary") return "14px"
  }};
  text-shadow: none !important;
  border: none;
  transition: 0.4s;
  cursor: pointer;
  :focus {
    outline: none !important;
    border: none !important;
  }
  :hover {
    box-shadow: ${({ type, disabled }) => {
      if (type === "primary") return "0px 8px 8px -2px rgba(166, 38, 81, 0.5)"
      if (type === "tertiary" && !disabled)
        return "0px 8px 8px -2px rgba(166, 38, 81, 0.5)"
    }};
    color: ${({ type, disabled }) => {
      if (type === "primary") return "#FFFFFF"
      if (type === "secondary") return "#A62651"
    }};
  }

  @media (max-width: 1140px) {
    padding: 11.25px 12.75px;
    max-width: ${({ type }) => {
      if (type === "primary") return "200px"
    }};
  }
  @media (max-width: 1024px) {
    font-size: 14.25px;
    line-height: 19px;
    padding: 11.25px 27.75px;
    text-align:center !important;
  }
  @media (max-width: 425px) {
    font-size: 12.21px;
    line-height: 16px;
    padding: 9.64px 23.79px;
  }
  @media (max-width: 350px) {
    font-size: 10.21px;
    line-height: 16px;
    padding: 9.64px 11.79px;
    
  }
`

export default FlipbaseButton
