import React from "react";
import styled from "styled-components";
import { Link } from 'gatsby';
import { colors, device } from "../../styles/constants";
import { rhythm } from "../../utils/typography";

/* Navbar Link */



interface NavbarLink {
    toRoute:string,
    activeRoutes: Array<string>,
    disabled: boolean,
}

const NavbarLink: React.FunctionComponent<NavbarLink> = ({
    children,
    toRoute,
    activeRoutes,
    ...otherProps
}): React.ReactElement => {

  return (
      <NavbarLinkStyled
        activeClassName="active"
        partiallyActive={true}
        to={`${toRoute}`} 
        {...otherProps} >
          {children}
      </NavbarLinkStyled>
  );
};

const NavbarLinkStyled = styled(Link)`
  font-family:FreightSans;
  font-weight:600;
  margin-right: ${rhythm(1 / 2)};
  color:${colors.dark};
  transition: 0.2s;
  height:80px;
  line-height: 77px;
  font-size:19px;
  padding:0 32px;
  :hover {
    color:${colors.mainColor};
    @media ${device.mobileL} { 
      color:${colors.dark};
    }
  }
  @media ${device.desktop} {
    padding:0 22px;
  }
  @media ${device.laptopL} {
    margin-right:0;
    padding:0 18px;
    font-size:16px;
  }
  @media (max-width:1200px) {
    padding:0 12px;

  }
  @media ${device.mobileL} { 
    color:${colors.dark};
    font-size:36px;
    line-height:44px;
    text-shadow:none;
    margin-left:24px;
  }
  @media (max-width: 1024px) {
    height: 60px;
    line-height: 60px;
  }
`;





export default NavbarLink;